// @flow

import type { Node } from 'react';
import { useIntl } from 'react-intl';

/**
 * FormatDate
 *
 * This is a component that uses react-intl to format dates based on same options used for react-intl (Intl API)
 * but it has a specific format handling when weekday option is provided
 * as this produce different formatted output in Firefox (and probably other browsers) and Node adn because of this
 * it will show errors when data is hydrated from server on client on Firefox (and probably other browsers)
 *
 * @param {Object} props
 * @returns {Node}
 */
export function FormatDate({ value, ...restProps }: Object): Node {
  const OPTION_VALUES = { SHORT: 'short', LONG: 'long' };
  const FORMATTED_OPTIONS = ['weekday', 'day', 'month', 'year'];
  const { formatMessage, formatDate } = useIntl();
  const dateOptionsKeys = Object.keys(restProps);

  // Format the date using intl if no weekday option is provided
  if (dateOptionsKeys.indexOf('weekday') === -1) {
    return formatDate(value, restProps);
  }

  // Select different translation based on the options that are provided
  let translationKey = null;
  if (restProps.weekday && restProps.day && restProps.month && restProps.year) {
    translationKey = 'common.date.formats.weekdayDayMonthYear';
  } else if (restProps.weekday && restProps.day && restProps.month) {
    translationKey = 'common.date.formats.weekdayDayMonth';
  } else if (restProps.weekday && (!restProps.day || !restProps.month || !restProps.year)) {
    translationKey = 'common.date.formats.weekday';
  } else {
    // Fallback to the default formatting
    return formatDate(value, restProps);
  }

  // Format message
  return formatMessage(
    { id: translationKey },
    dateOptionsKeys.reduce<Object>((accumulator: Object, optionKey: string) => {
      const optionValue = restProps[optionKey];

      if (FORMATTED_OPTIONS.indexOf(optionKey) > -1) {
        // Use formatData from react-intl to format different parts send in the options,
        // before combining it into the final translation string
        const formattedOption = formatDate(value, { [optionKey]: optionValue });
        accumulator[optionKey] =
          optionValue === OPTION_VALUES.SHORT
            ? formatMessage(
                { id: `common.date.options.short.${optionKey}` },
                { value: formattedOption },
              )
            : formattedOption;
      } else {
        accumulator[optionKey] = optionValue;
      }

      return accumulator;
    }, {}),
  );
}
