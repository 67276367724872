// @flow

import classNames from 'classnames';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@riseart/common';
import { application as CONFIG_APP } from 'Config';
import { useAuth } from 'shared_services/redux/hooks/useAuth';
import { useUrlParams } from 'shared_data/providers/url/useUrlParams';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { selectLensSearchOpened } from 'shared_services/redux/selectors/gui';
import { GUI_PROPERTIES } from 'shared_models/Gui';
import { GOOGLE_TAGMANAGER_ACTIONS } from 'shared_data/providers/google/tagmanager/Actions';
import { guiUpdate } from 'shared_services/redux/actions/application/gui';
import { actionFactory } from 'shared_services/riseart/utils/Utils';

import { submitBtnCls, searchFieldLensCls } from 'shared_components/forms/search/Form.less';

type Props = {
  isMobile?: boolean,
  trigger: string,
  children: Node | Function,
};

/**
 * LensButton
 *
 * @returns {Node}
 */
export function LensButton({ isMobile = false, trigger, children }: Props): Node {
  const LENS_ACL_ROLE = CONFIG_APP.acl.resourcePermissions.lens;
  const dispatch: Function = useDispatch();
  const { formatMessage } = useIntl();
  const { translatedLocation: location } = useUrlParams();
  const { l } = queryString.parse(location.search) || {};
  const isOpened = useSelector(selectLensSearchOpened);
  const lensUrl = UrlAssembler.artList({ search: { l: '' } });
  const { isAllowed, authUrl } = useAuth(LENS_ACL_ROLE, {
    buildUrl: true,
    urlOptions: {
      search: { forward: lensUrl, role: LENS_ACL_ROLE },
    },
  });

  // Open the overlay on component mounts and if l parameter is in query string but is empty
  useEffect(() => {
    if (isMobile && isAllowed && !isOpened && [null, ''].indexOf(l) > -1) {
      dispatch(guiUpdate(GUI_PROPERTIES.LENS_SEARCH, { opened: true }));
    }
  }, [dispatch, isAllowed, isMobile, isOpened, l]);

  /**
   * handleClick
   */
  const handleClick = useCallback(
    (e: Event) => {
      e.stopPropagation();

      dispatch(actionFactory(GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_START, { trigger }));

      if (isMobile && isAllowed) {
        e.preventDefault();
        dispatch(guiUpdate(GUI_PROPERTIES.LENS_SEARCH, { opened: true }));
      }
    },
    [dispatch, isAllowed, isMobile, trigger],
  );

  const linkProps = {
    obfuscate: true,
    ...(isMobile && isAllowed ? { obflinkProps: { stopPropagation: true } } : null),
    rel: 'nofollow',
    className: classNames(submitBtnCls, searchFieldLensCls),
    title: formatMessage({ id: 'forms.search.lens.openLens' }),
    to: isAllowed ? lensUrl : authUrl,
    onClick: handleClick,
  };

  return typeof children === 'function' ? (
    children(linkProps)
  ) : (
    <Link {...linkProps}>{children}</Link>
  );
}
