// @flow

import classNames from 'classnames';
import React, { useRef, useEffect, useState } from 'react';
import type { Node, RefObject } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Row, Col } from '@riseart/antd-provider';
import { Button } from '@riseart/common';
import { CameraIcon, PhotoFolderIcon } from '@riseart/icons';
import { GOOGLE_TAGMANAGER_ACTIONS } from 'shared_data/providers/google/tagmanager/Actions';
import { actionFactory } from 'shared_services/riseart/utils/Utils';
import {
  FileUploadInfo,
  FileUploadInfoItem,
  STATUS as UPLOAD_STATUS,
} from 'shared_components/common/file/UploadInfo';
import { VIEW } from 'shared_components/forms/search/lens/constants';
import { MESSAGE_TYPES, RiseartLogger } from 'shared_services/riseart/Logger';

import {
  lensOverlayVideoCls,
  lensOverlayPictureCls,
  lensOverlayPictureShowCls,
  lensOverlayPictureBtnCls,
  lensOverlayToolbarCls,
  lensOverlayCameraCls,
  lensOverlayScreenOptionsCls,
  lensOverlayScreenOptionsContentCls,
} from 'shared_components/forms/search/lens/Overlay.less';

type Props = {
  loading: boolean,
  uploadedImages: Array<Object | null>,
  onViewChange: Function,
  handleFileUpload: Function,
  children: Node,
};

/**
 * LensCameraView
 *
 * @param {Props} props
 * @returns {Node}
 */
export function LensCameraView({
  loading,
  uploadedImages,
  onViewChange,
  handleFileUpload,
  children,
}: Props): Node {
  const videoRef: RefObject<HTMLMediaElement | null> = useRef(null);
  const videoStreamRef: RefObject<any | null> = useRef(null);
  const canvasRef: RefObject<HTMLCanvasElement | null> = useRef(null);
  const dispatch: Function = useDispatch();
  const { formatMessage } = useIntl();
  const [error, setError] = useState<string | null>(null);

  const startCamera = async () => {
    try {
      setError(null);
      // Call open camera GTM event
      dispatch(actionFactory(GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_CAMERA_OPEN));

      // Start video
      videoStreamRef.current = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = videoStreamRef.current;
      }
    } catch (error) {
      let messageId = 'errors.default.details';

      // Add precise error message
      if (error.name === 'NotAllowedError') {
        messageId = 'forms.search.lens.overlay.error.permissions';
      } else if (error.name === 'NotFoundError') {
        messageId = 'forms.search.lens.overlay.error.device';
      } else {
        // Log error
        RiseartLogger.message({ message: error.message, level: MESSAGE_TYPES.ERROR });
      }

      setError(formatMessage({ id: messageId }));
    }
  };

  const stopCamera = () => {
    if (videoStreamRef.current) {
      // Stop all stream tracks
      videoStreamRef.current.getTracks().forEach((track) => track.stop());

      // Clear the reference
      videoStreamRef.current = null;
    }

    // Remove the video source
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  // Start and stop camera
  useEffect(() => {
    // Start camera on mount
    if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      startCamera();
    }
    // Stop camera on unmounts
    return () => stopCamera();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * handleTakePicture
   */
  const handleTakePicture = () => {
    if (!videoStreamRef.current) {
      return false;
    }
    // Call camera capture GTM event
    dispatch(actionFactory(GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_CAMERA_CAPTURE));

    // Take the camera picture and render in canvas
    const context = canvasRef.current.getContext('2d');
    canvasRef.current.width = videoRef.current.videoWidth;
    canvasRef.current.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

    // Upload picture
    canvasRef.current.toBlob((blob) => {
      handleFileUpload([{ file: new File([blob], 'camera-image.jpg', { type: 'image/png' }) }]);
    }, 'image/png');
  };

  const isUploadingImages = !!(loading && uploadedImages && uploadedImages.length);

  return (
    <div className={lensOverlayCameraCls}>
      <video ref={videoRef} autoPlay className={lensOverlayVideoCls} />
      <canvas
        ref={canvasRef}
        className={classNames(
          lensOverlayPictureCls,
          isUploadingImages && lensOverlayPictureShowCls,
        )}
      />
      {isUploadingImages ? (
        <FileUploadInfo>
          {uploadedImages.map(
            ({ name, progress, status, errorMessage }: Object, idx: number): any => (
              <FileUploadInfoItem
                key={`${name}${idx}`}
                name={
                  [UPLOAD_STATUS.COMPLETED, UPLOAD_STATUS.ERROR].indexOf(status) === -1 ? (
                    <FormattedMessage id="forms.search.lens.overlay.processing">
                      {(text) => text}
                    </FormattedMessage>
                  ) : null
                }
                progress={progress}
                status={status}
                errorMessage={errorMessage}
              />
            ),
          )}
        </FileUploadInfo>
      ) : null}
      {error ? (
        <div className={lensOverlayScreenOptionsCls}>
          <div className={lensOverlayScreenOptionsContentCls}>{error}</div>
        </div>
      ) : null}
      {!loading ? (
        <div className={lensOverlayToolbarCls}>
          <Row justify="center" align="middle" gutter="28">
            <Col>{children}</Col>
            {!error ? (
              <Col>
                <Button
                  shape="circle"
                  size="xlarge"
                  className={lensOverlayPictureBtnCls}
                  onClick={handleTakePicture}
                >
                  <CameraIcon />
                </Button>
              </Col>
            ) : null}
            <Col>
              <Button shape="circle" size="large" onClick={onViewChange(VIEW.UPLOADS)}>
                <PhotoFolderIcon />
              </Button>
            </Col>
          </Row>
        </div>
      ) : null}
    </div>
  );
}
