// @flow

import React, { Fragment, useState, useCallback } from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { calcPercentDifference } from '@riseart/fe-utils';
import { CardTile } from '@riseart/card';
import { Overlay, OverlayHeader, OverlayBody, OverlayContainer } from '@riseart/layout';
import { Button, Heading } from '@riseart/common';
import { errors as ERRORS_ENUM } from 'Enum';
import { useQuery } from 'shared_services/apollo/useQuery';
import { formatCurrency, getStoreCodeByCurrencyCode } from 'shared_services/riseart/utils/Utils';
import { selectUserId } from 'shared_services/redux/selectors/user';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { FormatDate as formatDate } from 'shared_components/common/date/Format';
import { getOfferPicturePropsFromImages } from 'shared_components/pages/me/Offer';
import READ_USER_OFFER from 'shared_data/queries/user/readOffer.graphql';

type Props = {
  id: number,
};

/**
 * MeOfferDetails
 *
 * @param {Props} props
 */
export function OfferModal({ id }: Props): Node {
  const DATE_FORMAT_OPTIONS = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    weekday: 'short',
  };
  const { formatMessage } = useIntl();
  const buttonLabel = formatMessage({ id: 'components.cart.offer' });
  const locale = useLocale();
  const userId = useSelector(selectUserId);
  const [opened, setOpened] = useState(false);
  const toggleOverlay = useCallback(() => {
    setOpened(!opened);
  }, [opened]);
  const { data, loading: offerLoading } = useQuery(READ_USER_OFFER, {
    variables: { offerId: id, userId },
    skip: !userId || !opened,
    context: {
      customOptions: {
        errorSuppressFromResponse: true,
        error: { handler: ERRORS_ENUM.handlers.NOTIFICATION },
      },
    },
  });
  const { readUserOffer: offer } = data || {};
  const { art } = offer || {};
  const offerStoreCode =
    offer && offer.offerCurrencyCode ? getStoreCodeByCurrencyCode(offer.offerCurrencyCode) : null;

  return (
    <Fragment>
      <Overlay
        closeOnOutsideClick
        isVisible={opened}
        type="semitransparent"
        size="medium"
        onClose={toggleOverlay}
      >
        <OverlayContainer>
          <OverlayHeader border={false}>
            <Heading tag="h3" level="3">
              {buttonLabel}
            </Heading>
          </OverlayHeader>
          <OverlayBody>
            {!offerLoading && offer ? (
              <CardTile
                displayStyle="main"
                title={
                  (art && art.title) ||
                  formatMessage({ id: 'components.me.offer.title' }, { sku: offer.productSku })
                }
                author={
                  art && art.artist ? (
                    <Fragment>
                      {' '}
                      {formatMessage({ id: 'common.byNameLowercase' }, { name: art.artist.name })}
                    </Fragment>
                  ) : null
                }
                details={formatMessage({ id: `components.me.offer.status.${offer.status}` })}
                price={
                  <Fragment>
                    <s>
                      {formatCurrency(offer.productPrice, offerStoreCode, undefined, {
                        locale: locale && locale.name,
                      })}
                    </s>
                    &nbsp;&nbsp;
                    <span>
                      {formatCurrency(offer.offerAmount, offerStoreCode, undefined, {
                        locale: locale && locale.name,
                      })}
                    </span>
                  </Fragment>
                }
                discount={`(${formatMessage(
                  {
                    id: 'components.me.offer.percentDiscount',
                  },
                  {
                    discount: `${calcPercentDifference(offer.offerAmount, offer.productPrice)}%`,
                  },
                )})`}
                additional={
                  offer.created || offer.expiryDate ? (
                    <Fragment>
                      {offer.created
                        ? formatMessage(
                            { id: 'components.me.offer.created' },
                            {
                              date: formatDate({ value: offer.created, ...DATE_FORMAT_OPTIONS }),
                            },
                          )
                        : null}
                      {offer.created && offer.expiryDate ? <br /> : null}
                      {offer.expiryDate
                        ? formatMessage(
                            { id: 'components.me.offer.expires' },
                            {
                              date: formatDate({ value: offer.expiryDate, ...DATE_FORMAT_OPTIONS }),
                            },
                          )
                        : null}
                    </Fragment>
                  ) : null
                }
                pictureProps={getOfferPicturePropsFromImages(art, 'TYPE_MAIN_SQUARE')}
              />
            ) : (
              <IsomorphicRipple
                isActive={offerLoading}
                customStyles={{ position: 'relative', height: '100px' }}
              />
            )}
          </OverlayBody>
        </OverlayContainer>
      </Overlay>

      <Fragment>
        {' | '}
        <Button type="link" title={buttonLabel} onClick={toggleOverlay}>
          {buttonLabel}
        </Button>
      </Fragment>
    </Fragment>
  );
}
