// @flow

import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Heading, Button } from '@riseart/common';
import { Overlay } from '@riseart/layout';
import { BackIcon, CloseIcon, GalleryIcon } from '@riseart/icons';
import { useUploadedFiles, FileUploader } from '@riseart/files';
import { components as CONFIG_COMPONENTS, file as CONFIG_FILE } from 'Config';
import { errors as ENUM_ERRORS, file as ENUM_FILE } from 'Enum';
import { GUI_PROPERTIES } from 'shared_models/Gui';
import { GOOGLE_TAGMANAGER_ACTIONS } from 'shared_data/providers/google/tagmanager/Actions';
import { useUrlParams } from 'shared_data/providers/url/useUrlParams';
import { delay, actionFactory } from 'shared_services/riseart/utils/Utils';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import { selectLensSearchOpened } from 'shared_services/redux/selectors/gui';
import { guiUpdate } from 'shared_services/redux/actions/application/gui';
import { selectUserId } from 'shared_services/redux/selectors/user';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { WithOffsetHeight } from 'shared_hocs/gui/withOffsetHeight';
import { VIEW } from 'shared_components/forms/search/lens/constants';
import { LensCameraView } from 'shared_components/forms/search/lens/views/Camera';
import { LensGalleryView } from 'shared_components/forms/search/lens/views/Gallery';
import { LensMainView } from 'shared_components/forms/search/lens/views/Main';
import LIST_IMAGE_UPLOADS from 'shared_data/queries/file/listImageUploads.graphql';

import { FileDropzone } from 'shared_components/common/file/Dropzone';
import type { ImageType } from 'shared_components/forms/search/Lens';
import { createFileUpload, updateFileUpload } from 'shared_data/handlers';

import {
  lensOverlayCls,
  lensOverlayHeaderCls,
} from 'shared_components/forms/search/lens/Overlay.less';

/**
 * LensOverlay
 *
 * @returns {Node}
 */
export function LensOverlay(): Node {
  const ITEMS_PER_PAGE = 25;
  const { imageUpload: IMAGE_UPLOAD_ENUM } = ENUM_FILE;
  const { imageUpload: IMAGE_UPLOAD_CONFIG } = CONFIG_FILE;

  // Helpers
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch: Function = useDispatch();

  // Selectors
  const userId = useSelector(selectUserId);
  const isOpened = useSelector(selectLensSearchOpened);
  const { translatedLocation: location } = useUrlParams();

  // State
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(VIEW.MAIN);

  // Fetch data
  const [
    fetchImagesUploads,
    { loading: fetchImagesLoading, data, refetch: refetchImageUploadsList },
  ] = useLazyQuery(LIST_IMAGE_UPLOADS, {
    skip: !userId || !isOpened,
    variables: {
      sort: 'id',
      order: 'DESC',
      items: ITEMS_PER_PAGE,
      page: 1,
      sign: true,
      hierarchy: false,
      filters: {
        type: { value: IMAGE_UPLOAD_ENUM.type.TYPE_VISUAL_SEARCH_USER_SQUARE },
        objectType: { value: IMAGE_UPLOAD_ENUM.object.type.USER },
        objectId: { value: userId },
      },
    },
  });

  useEffect(() => {
    if (isOpened && userId && view === VIEW.UPLOADS) {
      fetchImagesUploads();
    }
  }, [view, userId, isOpened, fetchImagesUploads]);

  /**
   * handleView
   */
  const handleView = useCallback(
    (view: string) => () => {
      setLoading(false);
      setView(view);
    },
    [],
  );

  /**
   * handleClose
   */
  const handleClose = useCallback(() => {
    const { l, ...restSearchParams } = queryString.parse(location.search) || {};
    history.replace([location.pathname, queryString.stringify(restSearchParams)].join(''));

    dispatch(guiUpdate(GUI_PROPERTIES.LENS_SEARCH, { opened: false }));
  }, [dispatch, history, location.pathname, location.search]);

  // File uploads
  const actionErrorAdd = useCallback(
    (error: string, file: Record<string, any>) =>
      dispatch(
        errorAdd({
          detail: `${file && file.path ? `[${file.path}] ` : ''}${error}`,
          level: ENUM_ERRORS.levels.WARNING,
          expire: 20,
        }),
      ),
    [dispatch],
  );
  const {
    uploadedFiles,
    setUploadedFiles,
    handleFileDrop: handleFileDropCallback,
    handleFileValidation,
  } = useUploadedFiles({
    filesByType: { [IMAGE_UPLOAD_ENUM.category.VISUAL_SEARCH_USER]: [] },
    maxFileSize: IMAGE_UPLOAD_CONFIG.maxFilesize,
    sizeValidationMessage: formatMessage(
      { id: 'forms.common.largerFilesize' },
      { maxFilesize: IMAGE_UPLOAD_CONFIG.maxFilesize },
    ),
  });
  const visualSearchUploadedImages =
    (uploadedFiles && uploadedFiles[IMAGE_UPLOAD_ENUM.category.VISUAL_SEARCH_USER]) || null;

  /**
   * handleFileUpload
   *
   * @param {Record<string, any>[]} files
   * @param {ImageType} type
   * @param {(state: Record<string, any> | null) => () => void} callback
   * @returns {void}
   */
  const handleFileUpload = (
    files: Record<string, any>[],
    type: ImageType,
    callback: (state: Record<string, any> | null) => () => void,
  ) => {
    setLoading(true);
    const FileUploadClient = new FileUploader(
      FileUploader.flattenFiles({ [type]: files }),
      userId,
      ENUM_FILE.object.type.IMAGE_UPLOAD,
      createFileUpload,
      updateFileUpload,
      actionErrorAdd,
    );
    FileUploadClient.subscribe(FileUploader.eventsTypes.ERROR, () => {
      setLoading(false);
    });
    FileUploadClient.subscribe(FileUploader.eventsTypes.CREATE_UPLOAD, (transactionState) => {
      dispatch(actionFactory(GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_UPLOAD_START, transactionState));
    });
    FileUploadClient.subscribe(FileUploader.eventsTypes.UPDATE, (fileUploadState) => {
      callback(fileUploadState.files);
    });
    FileUploadClient.subscribe(FileUploader.eventsTypes.COMPLETE, (fileUploadState) => {
      // Trigger GTM action for completed upload
      dispatch(
        actionFactory(GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_UPLOAD_COMPLETE, fileUploadState),
      );

      // Refetch images list with delay
      delay(
        () =>
          refetchImageUploadsList()
            .then((response) => {
              callback(fileUploadState.files);
              const { id, parentId } = response.data.listImageUploads.items[0];
              handleClose();
              history.push(UrlAssembler.artList({ search: { l: parentId || id } }));
            })
            .catch(() => callback(null))
            .finally(() => setLoading(false)),
        CONFIG_COMPONENTS.search.lens.overlay.delay,
      );
    });
    FileUploadClient.process();
  };

  /**
   * handleFileDrop
   *
   * @param {ImageType} type
   * @returns {(files: Record<string, any>[]) => void}
   */
  const handleFileDrop = (type: ImageType) => (files: Record<string, any>[]) => {
    handleFileUpload(files, type, handleFileDropCallback(type));
  };

  // Reset the view when closing the overlay
  useEffect(() => {
    if (!isOpened) {
      setView(VIEW.MAIN);
      setUploadedFiles({ [IMAGE_UPLOAD_ENUM.category.VISUAL_SEARCH_USER]: [] });
    }
  }, [isOpened, setUploadedFiles, IMAGE_UPLOAD_ENUM.category.VISUAL_SEARCH_USER]);

  const uploadImageComponent = (
    <FileDropzone
      onDrop={(files) => {
        handleFileDrop(IMAGE_UPLOAD_ENUM.category.VISUAL_SEARCH_USER)(files);
      }}
      validator={handleFileValidation}
      accept={IMAGE_UPLOAD_CONFIG.acceptedFiles}
      multiple={false}
    >
      <Button shape="circle" size="large">
        <GalleryIcon />
      </Button>
    </FileDropzone>
  );

  return isOpened ? (
    <WithOffsetHeight>
      {({ notificationsHeight }) => (
        <Overlay
          className={lensOverlayCls}
          topOffset={notificationsHeight}
          isVisible
          onClose={handleClose}
        >
          <div className={lensOverlayHeaderCls}>
            <Heading level="3" tag="h4" align="center">
              {formatMessage({ id: 'forms.search.lens.overlay.title' })}
            </Heading>
            {view === VIEW.MAIN ? (
              <Button shape="circle" size="medium" onClick={handleClose}>
                <CloseIcon />
              </Button>
            ) : (
              <Button shape="circle" size="medium" onClick={handleView(VIEW.MAIN)}>
                <BackIcon />
              </Button>
            )}
          </div>
          {view === VIEW.MAIN ? (
            <LensMainView
              loading={loading}
              uploadedImages={visualSearchUploadedImages}
              onViewChange={handleView}
            >
              {uploadImageComponent}
            </LensMainView>
          ) : null}
          {view === VIEW.CAMERA ? (
            <LensCameraView
              loading={loading}
              uploadedImages={visualSearchUploadedImages}
              onViewChange={handleView}
              handleFileUpload={handleFileDrop(IMAGE_UPLOAD_ENUM.category.VISUAL_SEARCH_USER)}
            >
              {uploadImageComponent}
            </LensCameraView>
          ) : null}
          {view === VIEW.UPLOADS ? (
            <LensGalleryView
              loading={fetchImagesLoading}
              images={data && data.listImageUploads && data.listImageUploads.items}
              onClose={handleClose}
            />
          ) : null}
        </Overlay>
      )}
    </WithOffsetHeight>
  ) : null;
}
