// @flow

import { useSelector } from 'react-redux';
import { useAclAccess } from '@riseart/fe-utils';
import { application as CONFIG_APP } from 'Config';
import { selectAclRole } from 'shared_services/redux/selectors/auth';
import { selectVisitorProperty } from 'shared_services/redux/selectors/visitor';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';

/**
 * useAuth
 *
 * @param {string} resourcePermissions
 * @param {Object} options
 * @returns {Object}
 */
export function useAuth(
  resourcePermissions: string,
  { buildUrl = false, urlOptions = {} }: Object,
): Object {
  const aclRole = useSelector(selectAclRole);
  const visitorRole = useSelector(selectVisitorProperty('role'));
  const { isAllowed } = useAclAccess({
    role: aclRole,
    resourcePermissions,
    rolesHierarchy: CONFIG_APP.acl.rolesHierarchy,
  });

  // Check if visitor has account
  const hasAccount =
    CONFIG_APP.acl.nonRegisteredRoles.acl.indexOf(aclRole) === -1 ||
    CONFIG_APP.acl.nonRegisteredRoles.visitor.indexOf(visitorRole) === -1;

  return {
    hasAccount,
    isAllowed,
    aclRole,
    visitorRole,
    // build url only if requested in options
    ...(buildUrl ? { authUrl: UrlAssembler.auth({ hasAccount }, urlOptions) } : null),
  };
}
