// @flow

import classNames from 'classnames';
import React, { Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { InfoIcon, ErrorIcon, WarningIcon, TickIcon } from '@riseart/icons';

import {
  fileUploadInfoCls,
  fileUploadInfoErrorCls,
  fileUploadInfoItemCls,
  fileUploadInfoIconCls,
  fileUploadInfoContentCls,
  fileUploadInfoMessageCls,
  fileUploadInfoStatusCls,
  fileUploadInfoProgressCls,
} from 'shared_components/common/file/UploadInfo.less';

export const STATUS = { COMPLETED: 'completed', ERROR: 'error' };

type FileInfoItemProps = {
  name?: string | Node,
  size?: number,
  progress: number,
  status: string,
  errorMessage: string,
};

/**
 * FileUploadInfoItem
 * @param {FileInfoItemProps} props
 * @returns {Node}
 */
export function FileUploadInfoItem({
  name,
  size,
  progress,
  status,
  errorMessage,
}: FileInfoItemProps): Node {
  const showProgress = [STATUS.COMPLETED, STATUS.ERROR].indexOf(status) === -1;

  return (
    <div
      className={classNames(
        fileUploadInfoItemCls,
        status === STATUS.ERROR && fileUploadInfoErrorCls,
      )}
    >
      <div className={fileUploadInfoIconCls}>{errorMessage ? <WarningIcon /> : <InfoIcon />}</div>
      <div className={fileUploadInfoContentCls}>
        {name || size ? (
          <div>
            {name ? <strong>{name}</strong> : null}
            {size ? (
              <Fragment>
                {' '}
                - {parseInt(size / 1024, 10)}{' '}
                <FormattedMessage id="common.kb">{(label) => label}</FormattedMessage>
              </Fragment>
            ) : null}
          </div>
        ) : null}
        {status === STATUS.ERROR ? (
          <div className={fileUploadInfoMessageCls}>{errorMessage}</div>
        ) : null}
        {status === STATUS.COMPLETED ? (
          <div className={fileUploadInfoMessageCls}>
            <FormattedMessage id="components.fileDropzone.uploaded">
              {(label) => label}
            </FormattedMessage>
          </div>
        ) : null}
        {showProgress ? (
          <div className={fileUploadInfoProgressCls}>
            <div style={{ width: `${progress || 0}%` }} />
          </div>
        ) : null}
      </div>
      {!showProgress ? (
        <div className={fileUploadInfoStatusCls}>
          {status === STATUS.COMPLETED ? <TickIcon /> : <ErrorIcon />}
        </div>
      ) : null}
    </div>
  );
}

type FileInfoProps = {
  children: (typeof FileUploadInfoItem)[],
};

/**
 * FileUploadInfo
 *
 * @param {FileInfoProps} props
 * @returns {Node}
 */
export function FileUploadInfo({ children }: FileInfoProps): Node {
  return <div className={fileUploadInfoCls}>{children}</div>;
}
