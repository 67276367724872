// @flow

import debounce from 'lodash/debounce';
import React, { useState, useEffect, useCallback } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { components as CONFIG_COMPONENTS } from 'Config';
import { search as ENUM_SEARCH } from 'Enum';
import { parseHash } from 'shared_services/riseart/utils/RouteUtils';
import { SearchForm } from 'shared_components/forms/search/Form';
import { gtmMainSearchText } from 'shared_data/providers/google/tagmanager/Actions';

/**
 * HeaderSearch
 *
 * @param {Props} props
 * @returns {Node}
 */
export function HeaderSearch({ children, onChange, ...props }: Object): Node {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { q: hashValue } = parseHash(location.hash) || {};
  const [value, setValue] = useState(hashValue);

  // Update state value if hash value is changed
  useEffect(() => {
    if (value !== hashValue) {
      setValue(hashValue);
    }
  }, [hashValue, value]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(value);
    }
  }, [value]); // eslint-disable-line

  // Push tracking event data
  const sendTrackingEvent = debounce((text) => {
    dispatch(gtmMainSearchText({ query: text, domain: ENUM_SEARCH.domain.ALL }));
  }, CONFIG_COMPONENTS.search.main.trackingEventTimeout);

  useEffect(() => {
    if (value) {
      sendTrackingEvent(value);
    }
  }, [value]); // eslint-disable-line

  // Handle change (update hash)
  const handleChange = useCallback(
    (inputValue) => {
      if (
        (inputValue &&
          inputValue.length &&
          inputValue.length >= CONFIG_COMPONENTS.search.main.minChar) ||
        (value && !inputValue)
      ) {
        history.replace(
          [
            location.pathname,
            location.search,
            inputValue ? `#q=${encodeURIComponent(inputValue)}` : '',
          ].join(''),
        );
      }
    },
    [value, location.pathname, location.search, history],
  );

  return (
    <SearchForm
      preventDefault
      displayStyle="horizontalInset"
      domain={ENUM_SEARCH.domain.ALL}
      initialValue={hashValue}
      onChange={handleChange}
      placeholder={formatMessage({ id: 'layouts.header.search.placeholder' })}
      timeoutMillisec={CONFIG_COMPONENTS.search.main.timeout}
      useQueryString={false}
      showLens
      {...props}
    />
  );
}
