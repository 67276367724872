// @flow

import React, { useEffect, useState, Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Paragraph } from '@riseart/common';
import { CameraIcon, PhotoFolderIcon } from '@riseart/icons';
import { Row, Col } from '@riseart/antd-provider';
import { NavigatorService } from 'shared_services/riseart/Navigator';
import { FileUploadInfo, FileUploadInfoItem } from 'shared_components/common/file/UploadInfo';
import { VIEW } from 'shared_components/forms/search/lens/constants';

import {
  lensOverlayScreenOptionsCls,
  lensOverlayScreenOptionsContentCls,
  lensOverlayMainBtnCls,
  lensOverlayToolbarCls,
  lensOverlayContentDescriptionCls,
  lensOverlayContentHintCls,
} from 'shared_components/forms/search/lens/Overlay.less';

type Props = {
  loading: boolean,
  uploadedImages: Array<Object | null>,
  onViewChange: Function,
  children: Node,
};

/**
 * LensMainView
 *
 * @param {Props} props
 * @returns {Node}
 */
export function LensMainView({ loading, uploadedImages, onViewChange, children }: Props): Node {
  const [hasCamera, setHasCamera] = useState(false);

  useEffect(() => {
    async function checkCamera() {
      const canUseCamera = await NavigatorService.hasCamera();
      setHasCamera(canUseCamera);
    }

    checkCamera();
  }, []);

  return (
    <Fragment>
      <div className={lensOverlayScreenOptionsCls}>
        <div className={lensOverlayScreenOptionsContentCls}>
          {!loading ? (
            <Fragment>
              <Paragraph className={lensOverlayContentDescriptionCls}>
                <FormattedMessage id="forms.search.lens.overlay.text">
                  {(label) => label}
                </FormattedMessage>
              </Paragraph>
              {hasCamera ? (
                <Fragment>
                  <Button
                    type="primary"
                    size="large"
                    className={lensOverlayMainBtnCls}
                    onClick={onViewChange(VIEW.CAMERA)}
                    icon={<CameraIcon />}
                  >
                    <FormattedMessage id="forms.search.lens.overlay.takePhoto">
                      {(label) => <Fragment> {label}</Fragment>}
                    </FormattedMessage>
                  </Button>
                  <Paragraph className={lensOverlayContentHintCls}>
                    <FormattedMessage id="forms.search.lens.overlay.cameraHint">
                      {(label) => label}
                    </FormattedMessage>
                  </Paragraph>
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}
          {loading && uploadedImages && uploadedImages.length ? (
            <FileUploadInfo>
              {uploadedImages.map(
                ({ name, size, progress, status, errorMessage }: Object, idx: number): any => (
                  <FileUploadInfoItem
                    key={`${name}${idx}`}
                    name={name}
                    size={size}
                    progress={progress}
                    status={status}
                    errorMessage={errorMessage}
                  />
                ),
              )}
            </FileUploadInfo>
          ) : null}
        </div>
      </div>
      {!loading ? (
        <div className={lensOverlayToolbarCls}>
          <Row justify="center" gutter="24">
            <Col>{children}</Col>
            <Col>
              <Button shape="circle" size="large" onClick={onViewChange(VIEW.UPLOADS)}>
                <PhotoFolderIcon />
              </Button>
            </Col>
          </Row>
        </div>
      ) : null}
    </Fragment>
  );
}
