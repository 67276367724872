// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '@riseart/antd-provider';
import { Image, Link, Paragraph } from '@riseart/common';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';

import {
  lensOverlayGalleryCls,
  lensOverlayScreenOptionsCls,
  lensOverlayScreenOptionsContentCls,
} from 'shared_components/forms/search/lens/Overlay.less';

type Props = {
  loading: boolean,
  images: Array<Object>,
  onClose: Function,
};

/**
 * LensGalleryView
 *
 * @param {Props} props
 * @returns {Node}
 */
export function LensGalleryView({ loading, images, onClose }: Props): Node {
  return (
    <div className={`${lensOverlayScreenOptionsCls} ${lensOverlayGalleryCls}`}>
      <div className={lensOverlayScreenOptionsContentCls}>
        <Paragraph>
          <FormattedMessage id="forms.search.lens.overlay.galleryText">
            {(label) => label}
          </FormattedMessage>
        </Paragraph>
        <IsomorphicRipple isActive={loading}>
          {!loading && (!images || !images.length) ? (
            <Paragraph>
              <FormattedMessage id="forms.search.lens.overlay.noImages">
                {(label) => label}
              </FormattedMessage>
            </Paragraph>
          ) : null}
          {images && images.length ? (
            <Row gutter={[8, 8]}>
              {images.map((image: Object) => (
                <Col key={image.id} span={6}>
                  <FormattedMessage id="forms.search.lens.overlay.linkTitle">
                    {(label) => (
                      <Link
                        key={image.id}
                        to={UrlAssembler.artList({ search: { l: image.parentId || image.id } })}
                        title={label}
                        onClick={onClose}
                      >
                        <Image src={image.signedUri} alt={`${image.id}`} />
                      </Link>
                    )}
                  </FormattedMessage>
                </Col>
              ))}
            </Row>
          ) : null}
        </IsomorphicRipple>
      </div>
    </div>
  );
}
