// @flow

import React, { useCallback } from 'react';
import type { Node } from 'react';
import { useDropzone } from 'react-dropzone';

import { fileDropzoneCls } from 'shared_components/common/file/Dropzone.less';

type Props = {
  onDrop: Function,
  children: Node,
  validator?: Function,
  accept?: Object,
  multiple?: boolean,
};

/**
 * FileDropzone
 *
 * @param {Props} props
 * @returns {Node}
 */
export function FileDropzone({ onDrop, children, ...rest }: Props): Node {
  const handleDrop = useCallback(
    (acceptedFiles: File[], fileRejections: { file: File, errors: [] }[]) => {
      const mappedAcceptedFiles = acceptedFiles.map((file) => ({ file }));
      onDrop([...mappedAcceptedFiles, ...fileRejections]);
    },
    [onDrop],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop, ...rest });
  return (
    <div className={fileDropzoneCls} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}
